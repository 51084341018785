import { useEffect, useState } from 'react'

export default function useIsEmbed() {
  const [isEmbed, setIsEmbed] = useState(false)
  useEffect(() => {
    try {
      setIsEmbed(window.self !== window.top)
    } catch (e) {
      setIsEmbed(true)
    }
  }, [])
  return isEmbed
}
